import { React, useContext } from "react"
import DashboardContext from "../DashboardContext"
import { Typography, Layout } from "antd"
import Button from "react-bootstrap/Button"
import axios from "../axios"
import { useNavigate } from "react-router-dom"

const { Title } = Typography
const { Header } = Layout

export default function WelcomeHeader() {
    const context = useContext(DashboardContext)
    const nav = useNavigate()

    const handleSignOut = () => {
        axios.get("/signOutUser", { withCredentials: true }).then((response) => {
            if (response.status == 200 && response.data.success == true) {
                nav("/")
            }
        })
    }

    return (
        <>
            {
                <Header className="welcome_header">
                    <img className="modal_icon" src="./winterLogo.png" />
                    <Title level={3}>Welcome, {context.userEmail}!</Title>
                    <Button variant="dark" onClick={handleSignOut} size="large">
                        Sign Out
                    </Button>
                </Header>
            }
        </>
    )
}
