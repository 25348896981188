import {} from "antd"
import { useEffect, useState, useContext } from "react"
import axios from "../axios"
import { Button, Modal } from "react-bootstrap"
import { Form, Input } from "antd"
import Card from "react-bootstrap/Card"
import { ArrowRight } from "react-bootstrap-icons"
import ClipLoader from "react-spinners/ClipLoader"

import DashboardContext from "../DashboardContext"
import cloneImage from "../images/clone.png"
import noImage from "../images/no-image.png"

import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const NFTCard = ({ nft }) => {
    const context = useContext(DashboardContext)
    const [projectDetails, setProjectDetails] = useState(null)
    const [loading, setLoading] = useState(true)
    const [transferSucceeded, setTransferSucceeded] = useState(false)
    const [transferFailed, setTransferFailed] = useState(false)
    const [waitingOnTransfer, setWaitingOnTransfer] = useState(false)
    const [clickedTransfer, setClickedTransfer] = useState(false)
    const [waitingOnPayment, setWaitingOnPayment] = useState(false)
    const [tokenName, setTokenName] = useState(null)
    const [contentType, setContentType] = useState(null)
    const [clickedCopy, setClickedCopy] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [addressToTransferTo, setAddressToTransferTo] = useState(null)

    const handleClose = () => setShowConfirmationModal(false)

    useEffect(() => {
        if (projectDetails === null && nft.project_id) {
            axios.get("/getProjectDetails?projectId=" + nft.project_id).then((response) => {
                if (response.status == 200) {
                    setProjectDetails(response.data)
                    setLoading(false)
                }
            })
        }
        if (!nft.project_id) {
            // marketplace NFTs, should have metadata already
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        getTokenName(nft.token_symbol)
    }, [])

    const getTokenName = (tokenSymbol) => {
        switch (tokenSymbol) {
            case "SOL":
                setTokenName("Solana")
                break
            case "ETH":
                setTokenName("Ethereum")
                break
            case "MATIC":
                setTokenName("Polygon")
                break
            case "ADA":
                setTokenName("Cardano")
            default:
                setTokenName("")
        }
    }

    function transferNFT() {
        setWaitingOnTransfer(true)
        setClickedTransfer(false)
        if (nft.project_id) {
            axios
                .post(
                    "/transferNFT?email=" +
                        context.userEmail +
                        "&projectId=" +
                        nft.project_id +
                        "&tokenId=" +
                        nft.token_id +
                        "&addressToMintTo=" +
                        addressToTransferTo +
                        "&tokenURI=" +
                        nft.tokenuri +
                        "&tokenSymbol=" +
                        nft.token_symbol +
                        "&mintId=" +
                        nft.mint_id,
                    {},
                    { withCredentials: true }
                )
                .then((response) => {
                    setWaitingOnTransfer(false)
                    if (response.status == 200 && response.data.success == true) {
                        setTransferSucceeded(true)
                    } else {
                        if (response.data.reason == "waiting_on_payment") {
                            setWaitingOnPayment(true)
                            setClickedTransfer(false) // they might pay and retry on the same page
                        } else {
                            setTransferFailed(true)
                        }
                    }
                })
        } else {
            // marketplace
            if (nft.token_symbol == "ADA") {
                // Cardano NFT transfer
                axios
                    .post(
                        "/transferNFT?email=" +
                            context.userEmail +
                            "&assetId=" +
                            nft.token_id +
                            "&addressToMintTo=" +
                            addressToTransferTo +
                            "&mintId=" +
                            nft.mint_id +
                            "&contractAddress=" +
                            nft.smart_contract_address +
                            "&tokenSymbol=" +
                            nft.token_symbol,
                        {},
                        { withCredentials: true }
                    )
                    .then((response) => {
                        setWaitingOnTransfer(false)
                        if (response.status == 200 && response.data.success == true) {
                            setTransferSucceeded(true)
                        } else {
                            setTransferFailed(true)
                        }
                    })
            } else {
                // ETH/Polygon NFT transfer
                axios
                    .post(
                        "/transferNFT?email=" +
                            context.userEmail +
                            "&tokenId=" +
                            nft.token_id +
                            "&addressToMintTo=" +
                            addressToTransferTo +
                            "&mintId=" +
                            nft.mint_id +
                            "&contractAddress=" +
                            nft.smart_contract_address +
                            "&tokenSymbol=" +
                            nft.token_symbol,
                        {},
                        { withCredentials: true }
                    )
                    .then((response) => {
                        setWaitingOnTransfer(false)
                        if (response.status == 200 && response.data.success == true) {
                            setTransferSucceeded(true)
                        } else {
                            setTransferFailed(true)
                        }
                    })
            }
        }
    }

    const handleTransfer = (values) => {
        setAddressToTransferTo(values["crypto_address"])
        setShowConfirmationModal(true)
    }

    useEffect(() => {
        async function checkUrlForVideo(url) {
            if (url.endsWith(".png" || url.endsWith(".jpeg"))) {
                setContentType("img")
                return false
            }
            if (url.includes(".mp4")) {
                setContentType("video")
                return true
            }
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
                },
            }
            const { headers } = await axios.get(url, config)
            if (headers["content-type"].includes("video")) {
                setContentType("video")
                return headers["content-type"]
            }
            setContentType("img")
            return false
        }

        if (nft.imageURL) {
            if (nft.imageURL.videoURL != null) {
                checkUrlForVideo(nft.imageURL.videoURL)
            } else {
                checkUrlForVideo(nft.imageURL.imageURL)
            }
        }
    }, [])

    return (
        <>
            <Card className="nft_card">
                <div className="bg-image hover-zoom">
                    {!loading && contentType === "video" ? (
                        <>
                            <video
                                className="post_mint_nft"
                                width="318"
                                loop={true}
                                autoPlay
                                controls
                                muted
                                preload="none"
                            >
                                <source
                                    src={
                                        nft.imageURL?.videoURL != undefined
                                            ? nft.imageURL.videoURL
                                            : nft.imageURL.imageURL
                                            ? nft.imageURL.imageURL
                                            : noImage
                                    }
                                    type="video/mp4"
                                />
                            </video>
                        </>
                    ) : (
                        <Card.Img
                            variant="top"
                            src={
                                nft.imageURL
                                    ? nft.imageURL.imageURL
                                        ? nft.imageURL.imageURL
                                        : noImage
                                    : noImage
                            }
                            className="brand_box"
                            onError={(e) => {
                                e.target.onerror = null
                                e.target.src = noImage
                            }}
                        />
                    )}
                </div>

                {!loading && (
                    <Card.Body style={{ width: "100%" }}>
                        <Card.Text>
                            {projectDetails ? projectDetails.projectName : nft.metadata?.collectionName}
                        </Card.Text>
                        {nft.token_symbol !== "SOL" && nft.token_symbol !== "ADA" ? (
                            <Card.Title> Token ID: #{nft.token_id}</Card.Title>
                        ) : null}
                        {nft.token_symbol === "ADA" && (
                            <>
                                <Card.Title> {nft.metadata?.displayName}</Card.Title>

                                <OverlayTrigger
                                    placement={"bottom"}
                                    overlay={
                                        <Tooltip id={`tooltip-bottom`} style={{ width: 160 }}>
                                            {clickedCopy ? "Copied!" : "Click to copy Asset ID"}
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            marginBottom: 10,
                                        }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(nft.token_id)
                                            setClickedCopy(true)
                                            setTimeout(() => {
                                                setClickedCopy(false)
                                            }, 2000)
                                        }}
                                    >
                                        <Card.Title
                                            style={{
                                                fontSize: "16px",
                                                textAlign: "center",
                                                minWidth: "180px",
                                            }}
                                        >
                                            {"Asset ID: " +
                                                nft.token_id.substring(0, 6) +
                                                "..." +
                                                nft.token_id.substring(nft.token_id.length - 6)}
                                        </Card.Title>
                                        <img
                                            src={cloneImage}
                                            style={{
                                                width: 20,
                                                height: 20,
                                                marginLeft: 5,
                                                marginTop: -5,
                                            }}
                                        />
                                    </div>
                                </OverlayTrigger>
                            </>
                        )}
                        {nft.project_id == 54 && (
                            <span>
                                Access the playbook{" "}
                                <a href="https://nft-playbook.xyz/nft-playbook-tier-3-8f30c9a67a6644aba4c515f4c500f170">
                                    here
                                </a>{" "}
                                using pass: WYrK3stRr5
                            </span>
                        )}
                    </Card.Body>
                )}
                {nft.transferred_out && <Card.Title className="text-muted">Transferred</Card.Title>}

                {waitingOnPayment && !transferSucceeded && (
                    <Card.Title className="text-muted">
                        You need to pay ethereum gas fees to transfer this NFT. Please check your email for an
                        invoice.
                    </Card.Title>
                )}

                {!nft.transferred_out && (
                    <>
                        {transferSucceeded && <Card.Title>Successfully transferred 🎉</Card.Title>}
                        {transferFailed && !waitingOnPayment && <Card.Text>Transfer failed 😥</Card.Text>}
                        {transferFailed && !waitingOnPayment && (
                            <Card.Title>Contact help@usewinter.com.</Card.Title>
                        )}

                        {clickedTransfer && !waitingOnTransfer && (
                            <>
                                <Form
                                    onFinish={handleTransfer}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <Form.Item
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            fontSize: "16px",
                                        }}
                                        name="crypto_address"
                                        rules={[
                                            {
                                                validator: async (rule, value) => {
                                                    if (nft.token_symbol == "SOL") {
                                                        if (value == undefined || value.indexOf(" ") >= 0) {
                                                            throw new Error("Not a valid crypto address!")
                                                        }
                                                    } else if (nft.token_symbol == "ADA") {
                                                        if (
                                                            !(
                                                                value.startsWith("addr1") ||
                                                                value.startsWith("addr_test1")
                                                            ) ||
                                                            value == undefined ||
                                                            // Also verify that the address is the right length
                                                            !(value.length == 103 || value.length == 108)
                                                        ) {
                                                            throw new Error("Not a valid cardano address!")
                                                        }
                                                    } else {
                                                        if (
                                                            value == undefined ||
                                                            !value.startsWith("0x") ||
                                                            value.indexOf(" ") >= 0
                                                        ) {
                                                            throw new Error("Not a valid crypto address!")
                                                        }
                                                    }
                                                },
                                            },
                                        ]}
                                        validateTrigger="handleTransfer"
                                    >
                                        {nft.token_symbol == "SOL" ? (
                                            <Input placeholder="e.g. 4ozgV2..." className="input" />
                                        ) : nft.token_symbol == "ADA" ? (
                                            <Input placeholder="e.g. addr1..." className="input" />
                                        ) : (
                                            <Input placeholder="e.g. 0xe0CB5..." className="input" />
                                        )}
                                    </Form.Item>
                                    <Button type="submit" variant="dark">
                                        <ArrowRight color="white" style={{ width: "100%" }} />
                                    </Button>
                                    <Modal show={showConfirmationModal} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirm Your Transfer</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p style={{ wordBreak: "break-all" }}>
                                                You are about to transfer your NFT to: <br />
                                                <br />
                                                <b>{addressToTransferTo}</b>
                                            </p>
                                            <p>
                                                Please double check that this is the right address. We cannot
                                                help you once your NFT has been transferred!
                                            </p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                            <Button variant="dark" onClick={transferNFT}>
                                                Transfer
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Form>
                                <span className="warning_text">
                                    {nft.token_symbol == "SOL" ? (
                                        <>
                                            <b>Please only transfer to a Solana wallet like Phantom.</b> We
                                            cannot help you if you make a mistake!
                                        </>
                                    ) : (
                                        <>
                                            Please make sure to put in the correct address. We cannot help you
                                            if you make a mistake!
                                        </>
                                    )}
                                </span>
                            </>
                        )}

                        {waitingOnTransfer && <ClipLoader color="black" loading={true} />}

                        {!transferFailed &&
                            !transferSucceeded &&
                            !clickedTransfer &&
                            !nft.transferred_out &&
                            !waitingOnTransfer && (
                                <Button
                                    variant="dark"
                                    onClick={() => setClickedTransfer(true)}
                                    disabled={nft.transferred_out}
                                >
                                    Transfer
                                </Button>
                            )}
                    </>
                )}
            </Card>
        </>
    )
}

export default NFTCard
