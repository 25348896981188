import { useState, useEffect, React, useContext } from "react"
import { Row, Col, Layout, Typography } from "antd"
import axios from "../axios"
import NFTCard from "./NFTCard"
import WelcomeHeader from "./WelcomeHeader"
import DashboardContext from "../DashboardContext"
import { useNavigate } from "react-router-dom"
import Grid from "@mui/material/Grid"
import ClipLoader from "react-spinners/ClipLoader"

const { Title } = Typography
const { Header, Content } = Layout

export default function ViewAllNFTs() {
    const [nfts, setNfts] = useState([])
    const [authdEmail, setAuthdEmail] = useState(null)
    const context = useContext(DashboardContext)
    const [loading, setLoading] = useState(true)
    const [loggedInLoadingNFTs, setLoggedInLoadingNFTs] = useState(false)
    const nav = useNavigate()

    useEffect(() => {
        if (authdEmail == null) {
            axios.get("/getLoggedInUser", { withCredentials: true }).then((response) => {
                if (response.data.success == true) {
                    setLoading(false)
                    const userEmail = response.data.email
                    setAuthdEmail(userEmail)
                    context.setUserEmail(userEmail)

                    if (nfts.length === 0) {
                        setLoggedInLoadingNFTs(true)
                        axios
                            .get(
                                `/getNFTs?email=${userEmail}&sandbox=${
                                    process.env.REACT_APP_WINTER_API_BASE_URL &&
                                    process.env.REACT_APP_WINTER_API_BASE_URL.includes("usewinter")
                                        ? false
                                        : true
                                }`
                            )
                            .then((response) => {
                                if (response.status == 200) {
                                    let nftData = response.data
                                    // sort so that nfts that weren't transferred out are first
                                    nftData.sort((a, b) => {
                                        if (a.transferred_out == false) {
                                            return -1
                                        } else {
                                            return 1
                                        }
                                    })
                                    setNfts(nftData)
                                    setLoggedInLoadingNFTs(false)
                                } else {
                                    // todo show error
                                }
                            })
                    }
                } else {
                    nav("/")
                }
            })
        }
    })

    return (
        <>
            {authdEmail != null && (
                <div className="view_nfts">
                    <WelcomeHeader />

                    <Content className="nfts_content">
                        {!loggedInLoadingNFTs && nfts.length > 0 && (
                            <Grid container spacing={5} className="nfts_content">
                                {nfts.map((value, index) => {
                                    return (
                                        <Grid
                                            className="nft_card_container"
                                            style={{ background: "transparent" }}
                                            key={value.mint_id}
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={4}
                                            xl={4}
                                        >
                                            <NFTCard nft={value} />{" "}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        )}

                        {!loggedInLoadingNFTs && nfts.length == 0 && <Title>No NFTs to show!</Title>}

                        {/* {loggedInLoadingNFTs && <Title>Loading NFTs..</Title>} */}

                        {loggedInLoadingNFTs && (
                            <Title>
                                <ClipLoader color="black" loading={true} />{" "}
                            </Title>
                        )}
                    </Content>
                </div>
            )}

            {loading && (
                <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: 5 }}>
                    <ClipLoader color="black" loading={true} />{" "}
                </div>
            )}
        </>
    )
}
