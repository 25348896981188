import { React, useState } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Layout } from "antd"
import "bootstrap/dist/css/bootstrap.min.css"
import "./winter.css"
import LoginPage from "./components/LoginPage"
import ViewAllNFTs from "./components/ViewAllNFTs"
import DashboardContext from "./DashboardContext"

export default function App() {
    const [userEmail, setUserEmail] = useState(null)

    return (
        <BrowserRouter>
            <DashboardContext.Provider value={{ userEmail: userEmail, setUserEmail: setUserEmail }}>
                <Layout className="page-layout">
                    <Routes>
                        <Route exact path="/" element={<LoginPage />} />
                        <Route exact path="/home" element={<ViewAllNFTs />} />
                    </Routes>
                </Layout>
            </DashboardContext.Provider>
        </BrowserRouter>
    )
}
