import { useState, useEffect, React, useContext } from "react"
import { Typography } from "antd"
import { useLocation } from "react-router-dom"
import axios from "../axios"
import { useNavigate } from "react-router-dom"
import { ArrowRight } from "react-bootstrap-icons"
import Button from "react-bootstrap/Button"
import ClipLoader from "react-spinners/ClipLoader"

import { Form, Input } from "antd"
const { Text, Title } = Typography

export default function LoginPage() {
    const queryParams = new URLSearchParams(useLocation().search)
    const [loginFailed, setLoginFailed] = useState(false)

    const token = queryParams.get("token")
    const [sentMagicLink, setSentMagicLink] = useState(false)
    const [authenticatedEmail, setAuthenticatedEmail] = useState("")
    const [inVerifyMode, setInVerifyMode] = useState(false)
    const [loading, setLoading] = useState(true)

    const nav = useNavigate()

    useEffect(() => {
        const authenticate = async () => {
            try {
                axios.get("/authMagicLink?token=" + token, { withCredentials: true }).then((response) => {
                    console.log(response)
                    if (
                        response.status == 200 &&
                        response.data.userResponse != undefined &&
                        response.data.userResponse.emails[0].verified == true
                    ) {
                        console.log(response)
                        setAuthenticatedEmail(response.data.userResponse.emails[0].email)
                        nav("/home")
                    } else {
                        setLoginFailed(true)
                        setInVerifyMode(false) // so user can retry
                        queryParams.delete("token") // delete the token so we don't make the same request again
                    }
                })
            } catch (err) {
                console.error("Error authenticating magic link")
            }
        }

        axios.get("/getLoggedInUser", { withCredentials: true }).then((response) => {
            if (response.data.success == true) {
                nav("/home")
            } else {
                setLoading(false)
                if (token) {
                    setInVerifyMode(true)
                    authenticate()
                }
            }
        })
    }, [])

    const handleSubmit = async (values) => {
        // make sure email is lowercased
        const userEmail = values["email"].toLowerCase()
        axios.get(`/createMagicLink?email=${userEmail}`).then((response) => {
            if (response.status == 200 && response.data.response.status_code == 200) {
                setLoginFailed(false) // in case this was previously true and this is a retry
                setSentMagicLink(true)
            }
        })
    }

    return (
        <Form onFinish={handleSubmit} className="login_page">
            <img src="./logo.png" style={{ maxWidth: "120px" }} />

            {loading && (
                <Title>
                    <ClipLoader color="black" loading={true} />{" "}
                </Title>
            )}

            {!loading && (
                <>
                    {loginFailed && (
                        <Text>
                            Login failed. Please try again. Note that each login link can only be used once.
                        </Text>
                    )}
                    {!sentMagicLink && authenticatedEmail == "" && !inVerifyMode && (
                        <>
                            <Text>Login with your email to view & transfer your NFTs!</Text>

                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, type: "email", message: "Please enter a valid email." },
                                ]}
                                validateTrigger="handleSubmit"
                            >
                                <Input placeholder="you@example.com" className="email_input" />
                            </Form.Item>
                            <Button type="submit" variant="outline-dark">
                                Enter
                            </Button>
                        </>
                    )}

                    {sentMagicLink && authenticatedEmail == "" && !inVerifyMode && (
                        <Text>We sent you a login link! Check your email. </Text>
                    )}
                    {inVerifyMode && !loginFailed && <Text>One second as we log you in..</Text>}
                </>
            )}
        </Form>
    )
}
